<template>
  <input class="flatpickr flatpickr-input form-control input" :value="value" type="text" :placeholder="placeholder" @input="$emit('input', $event.target.value)">
</template>
<style scoped>
</style>
<script>
require('../../node_modules/flatpickr/dist/flatpickr.min.css')
import Flatpickr from 'flatpickr'
import {zh} from 'flatpickr/dist/l10n/zh.js'
import Vue from 'vue'

const locales = {
  'zh': zh
}

export default {
  props: {
    placeholder: {
      type: String,
      required: false,
      default: 'Date'
    },
    options: {
      type: Object,
      required: true
    },
    value: {
      required: false
    }
  },
  data () {
    return {
      flatpickr: {},
      default: {}
    }
  },
  mounted () {
    // time pickers dont emit input events
    // so we use the on close to emit it for us and update the v-model
    let self = this
    this.default.onClose = function (selectedDates, dateStr) {
      self.$emit('input', dateStr)
    }

    this.flatpickr = new Flatpickr(this.$el, Object.assign({}, this.options, this.defaultOptions))
  },
  computed: {
    defaultOptions () {
      if (Vue.config.lang === 'en') {
        return Object.assign({}, this.default)
      } else {
        return Object.assign({
          locale: locales[Vue.config.lang]
        }, this.default)
      }
    }
  },
  destroyed () {
    this.flatpickr.destroy()
  },
  watch: {
    options: {
      handler () {
        this.flatpickr = new Flatpickr(this.$el, this.options)
      },
      deep: true
    }
  }
}
</script>
