import _ from 'lodash'
// var _Vue
// var options

// function Cart (Vue, options) {
function Cart () {
    // _Vue = Vue
  // _options = options
}

Cart.prototype.store = []

Cart.prototype.add = function (booking) {
  // only insert unique bookings
  let stored = _.find(this.store, function (element) {
    return _.isEqual(element, booking)
  })

  if (stored == null) {
    this.store.push(booking)
    return true
  } else {
    return false
  }
}

Cart.prototype.remove = function (booking) {
  this.store.splice(this.store.findIndex((element) => {
    return element === booking
  }), 1)
}

export default Cart
