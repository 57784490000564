<template>
  <b-row class="pt-3">
    <b-col class="pb-2" cols="12" lg="6">
      <user :user="user"></user>
    </b-col>
    <b-col class="pb-2" cols="12" lg="6">
      <teams :userId="user.userId" :showCreate="false"></teams>
    </b-col>
    <b-col class="pb-2" cols="12" lg="6">
      <invoices :userId="user.userId"></invoices>
    </b-col>
    <b-col class="pb-2" cols="12" lg="6">
      <event-log :userId="user.userId"></event-log>
    </b-col>
  </b-row>
</template>

<style scoped lang="scss">
</style>

<script>
import User from '@/components/User/User.vue'
import Teams from '@/components/User/Teams.vue'
import Invoices from '@/components/User/Invoices.vue'
import EventLog from '@/components/User/EventLog.vue'

export default {
  name: 'ViewUser',
  props: {
    user: {
      type: Object
    }
  },
  components: {
    User,
    Teams,
    Invoices,
    EventLog
  }
}
</script>
