<template lang="html">
  <div>
    <b-modal id="pricecalc" hide-footer size="lg" title="Pricing Calculator">
      <pricing-calculator v-model="defaultSchedule.pricePerSecond" :blockTime="display.blockTime" :maxTimePurchasable="display.maxTimePurchasable" showButton @close="$bvModal.hide('pricecalc')"></pricing-calculator>
    </b-modal>

    <div class="alert alert-danger" v-if="errors.length > 0">
      <ul>
        <li v-bind:key="index" v-for="(error, index) in errors">
          {{ error }}
        </li>
      </ul>
    </div>

    <b-container class="mt-3">
      <b-row class="h-100">
        <b-col cols="12" lg="3" v-if="Object.keys(selected).length === 0">
          <b-row class="mb-3">
            <b-card :header="$t('displayPricing.default')">
              <b-card-text>
                <div v-on:keyup.enter.prevent.stop="saveDefault()">
                  <b-form-group :label="$t('common.name')" label-for="defaultName">
                    <b-form-input id="defaultName" v-model="defaultSchedule.scheduleName"></b-form-input>
                  </b-form-group>
                  <b-form-group :label="$t('displayPricing.pps')" label-for="price">
                    <b-form-input id="price" v-model.number="defaultSchedule.pricePerSecond"></b-form-input>
                  </b-form-group>
                </div>
                <b-button class="float-left mt-1" @click="$bvModal.show('pricecalc')" variant="primary">Pricing
                  Calculator</b-button>
                <b-button class="float-right mt-1" name="button" variant="primary" @click="saveDefault()">
                  {{$t('buttons.save')}}</b-button>
              </b-card-text>
            </b-card>
          </b-row>
          <b-row class="mb-3">
            <b-card :header="$t('displayPricing.options')">
              <b-card-text>
                <div v-on:keyup.enter.prevent.stop="saveDefault()">
                  <b-form-group :label="$t('displays.loop')" label-for="loop-time">
                    <b-form-input id="loop-time" v-model="display.blockTime"></b-form-input>
                  </b-form-group>
                  <b-form-group :label="$t('displays.maxTimePurchasable')" label-for="max-time">
                    <b-form-input id="max-time" v-model="display.maxTimePurchasable"></b-form-input>
                  </b-form-group>
                  <b-button class="float-right mt-1" name="button" variant="primary" @click="updateDisplay()">
                    {{$t('buttons.save')}}</b-button>
                </div>
              </b-card-text>
            </b-card>
          </b-row>
        </b-col>
        <b-col cols="12" lg="9" v-if="Object.keys(selected).length === 0">
          <calendar class="m-2" :pricingSchedules="pricingSchedules" :display="display"
            :defaultSchedule="defaultSchedule" :defaultDate="defaultDate" @selected="selected = arguments[0]"
            @refresh="fetchPricingSchedules"></calendar>
        </b-col>
        <b-col cols="12" v-if="Object.keys(selected).length !== 0">
          <day-schedule class="m-2" :pricingSchedules="pricingSchedules" :display="display"
            :defaultSchedule="defaultSchedule" :date="selected" @finished="finish" @refresh="fetchPricingSchedules">
          </day-schedule>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import Calendar from "./Pricing/Calendar.vue";
import DaySchedule from "./Pricing/DaySchedule.vue";
import PricingCalculator from "./Components/PricingCalculator.vue";

export default {
  name: "DisplayPricing",
  props: {
    display: {
      type: Object,
      required: true
    }
  },
  components: {
    Calendar,
    DaySchedule,
    PricingCalculator
  },
  data() {
    return {
      pricingSchedules: [],
      defaultSchedule: {
        scheduleName: "Baseline Price"
      }, 
      errors: [],
      validation: true,
      selected: {},
      defaultDate: {
        month: moment().format("MMMM"),
        year: moment().format("YYYY"),
        day: moment().format("d")
      },
    };
  },
  mounted() {
    this.fetchPricingSchedules();
  },
  methods: {
    fetchPricingSchedules() {
      var scope = this
      this.$axios
        .get("v1/displays/" + this.$route.params.displayId + "/pricing")
        .then(
          response => {
            this.pricingSchedules = response.data.data;
            this.defaultSchedule =
              this.pricingSchedules.find(schedule => {
                return schedule.displayDefault;
              }) || {};
          }).catch(
        function (error) {
          if (!error.response) {
            scope.$notifications.add({
              title: 'Error',
              type: 'warning',
              body: error.message,
              timeout: true
            })
          } else if (error.response.status !== 404) {
            scope.$notifications.add({
              title: 'Error',
              type: 'warning',
              body: error.response.data.message,
              timeout: true
            })
          }
        }
      )
    },
    saveDefault() {
      this.validateMe();
      if (this.validation) {
        this.defaultSchedule.priority = 0;
        this.defaultSchedule.startTime = "00:00:00";
        this.defaultSchedule.endTime = "23:59:59";
        this.defaultSchedule.startDate = moment()
          .startOf('month')
          .local("en")
          .format("YYYY-MM-DD");
        this.defaultSchedule.endDate = moment()
          .local("en")
          .year("2037")
          .format("YYYY-MM-DD");
        this.defaultSchedule.displayDefault = true;
        this.defaultSchedule.displayId = this.$route.params.displayId;

        let url = "v1/displays/" + this.$route.params.displayId + "/pricing/";
        url += this.defaultSchedule.pricingScheduleId
          ? this.defaultSchedule.pricingScheduleId
          : "";

        this.$axios.post(url, this.defaultSchedule).then(
          () => {
            this.$notifications.add({
              title: "Updated",
              type: "success",
              body: this.$t("notifications.pricingDefaultUpdate"),
              timeout: true
            });
            this.fetchPricingSchedules();
          },
          response => {
            if (response.data.status === 400) {
              this.$notifications.add({
                title: "Error",
                type: "danger",
                body: response.data.message,
                timeout: true
              });
            }
          }
        );
      }
    },
    finish() {
      let date = arguments[0];
      this.defaultDate.day = date.day;
      this.defaultDate.month = date.month;
      this.defaultDate.year = date.year;
      this.selected = {};
    },
    validateMe() {
      this.errors = [];
      this.validation = true;
      this.stringTest(
        this.defaultSchedule.scheduleName,
        "Baseline Price Name",
        255
      );
      this.lengthTest(
        this.defaultSchedule.pricePerSecond,
        "Default Price Per Second",
        0
      );
    },
    stringTest(value, input, max) {
      if (!value) {
        this.errors.push(input + " is a required input");
        this.validation = false;
      } else if (value.length > max) {
        this.errors.push(
          input + " can only be a maxiumum of " + max + " characters"
        );
        this.validation = false;
      }
    },
    lengthTest(value, input, min, max) {
      if (value === undefined) {
        this.$notifications.add({
                title: "Error",
                type: "warning",
                body: input + " is a required input",
                timeout: true
              });
        this.validation = false;
        return;
      }
      if (min !== undefined) {
        if (value < min) {
          this.$notifications.add({
                title: "Error",
                type: "warning",
                body: input + " has to be a minimum of " + min,
                timeout: true
              });
          this.validation = false;
          return;
        }
      }
      if (max !== undefined) {
        if (value > max) {
                    this.$notifications.add({
                title: "Error",
                type: "warning",
                body: input + " can only be a maximum of " + max,
                timeout: true
              });
          this.validation = false;
          return;
        }
      }
    },
    updateDisplay() {
      this.validateDisplay();
      if (this.validation) {
        this.$axios
          .post("v1/displays/" + this.display.displayId, this.display)
          .then(
            () => {
              // success
              this.$notifications.add({
                title: "Updated",
                type: "success",
                body: this.$t("notifications.displayUpdate"),
                timeout: true
              });
            },
            () => {
              // error
              this.$notifications.add({
                title: "Error",
                type: "danger",
                body: this.$t("notifications.displayUpdateError"),
                timeout: true
              });
            }
          );
      }
    },
    validateDisplay() {
      this.validation = true;
      this.errors = [];
      this.lengthTest(this.display.blockTime, "loop time", 10, 3600);
      this.lengthTest(this.display.maxTimePurchasable, "max purchasable time", 10, 3600);
      this.integerTest(this.display.blockTime, "loop time");
      this.integerTest(this.display.maxTimePurchasable, "max purchasable time");
    },
    integerTest(value, input) {
      if (value % 1 !== 0) {
        this.errors.push(input + " can only be a whole number");
        this.validation = false;
      }
    }
  }
};
</script>

<style lang="scss">
.update {
  padding: 15px;
}
</style>
