<template lang="html">
  <div>

    <b-modal id="create" hide-footer size="lg" title="New Pricing Schedule">

      <create-schedule :display="display" :defaultSchedule="defaultSchedule" @refresh="$emit('refresh')">
      </create-schedule>

    </b-modal>

    <b-form inline class="pb-2">

              <b-form-input type="number" step="1" v-model="year" min="2015" max="2100"></b-form-input>

                <b-form-select v-model="monthValue"  id="month" :options="months"></b-form-select>
              

      <b-button class="flex-float-right" @click="$bvModal.show('create')" variant="primary">{{$t('buttons.create')}}
      </b-button>

    </b-form>
    <div class="month h-100">
      <div class="day day-header" :key="day" v-for="day in 7">
        {{day-1 | dayString}}
      </div>
      <div class="day padded" :key="day" v-for="day in firstDayOfMonth">
        <!-- this is warning about unused variable day -->
        {{ day }}
      </div>
      <div class="day" :key="day" v-for="day in days" @click="$emit('selected', { day: day, month: month, year: year })">
        <div class="mb-1 border-top-0">
          {{day}}
        </div>
        <div :key="schedule.scheduleId" v-for="schedule in filteredSchedules(day)" class="">
          <div class="schedule" :style="'background-color:' + stringToColor(schedule.scheduleName)">
            {{schedule.scheduleName}}
          </div>
        </div>
      </div>
      <div class="day padded" :key="day" v-for="day in endPadding">
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import stringToColor from '../../../mixins/stringToColor.js'
import CreateSchedule from './CreateSchedule.vue'

export default {
  name: 'MonthPricing',
  mixins: [stringToColor],
  props: {
    display: {
      type: Object,
      required: true
    },
    pricingSchedules: {
      type: Array,
      required: true
    },
    defaultSchedule: {
      type: Object,
      required: true
    },
    defaultDate: {
      type: Object,
      required: false,
      default: () => {
        return {
          month: 0,
          year: 2017
        }
      }
    }
  },
  components: {
    CreateSchedule
  },
  data () {
    return {
      months: moment.months(), // ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      days: 0,
      firstDayOfMonth: 0,
      month: '',
      year: 2017,
      monthValue: 'January',
      show: false
    }
  },
  computed: {
    endPadding () {
      let value = 7 - ((this.days + this.firstDayOfMonth) % 7)
      if (value === 7) {
        return 0
      } else {
        return value
      }
    }
  },
  mounted () {
    this.month = this.defaultDate.month
    this.year = this.defaultDate.year
    this.monthValue = moment().month(this.month).format('MMMM')

    let now = moment().month(this.month).year(this.year)
    this.days = now.daysInMonth()
    this.month = now.month()
    this.year = now.year()
    this.firstDayOfMonth = now.startOf('month').weekday()
  },
  methods: {
    weekDay (value) {
      return moment().month(this.month).year(this.year).date(value).format('dddd')
    },
    filteredSchedules (day) {
      let date = moment().year(this.year).month(this.month).date(day)
      let schedules = []
      for (let schedule of this.pricingSchedules) {
        if (date.isBetween(schedule.startDate, schedule.endDate)) {
          schedules.push(schedule)
        }
      }
      return schedules
    }
  },
  watch: {
    monthValue () {
      let selected = moment().month(this.monthValue).year(this.year)
      this.month = selected.month()
      this.days = selected.daysInMonth()
      this.year = selected.year()
      this.firstDayOfMonth = selected.startOf('month').weekday()
    },
    year () {
      let selected = moment().month(this.monthValue).year(this.year)
      this.month = selected.month()
      this.days = selected.daysInMonth()
      this.firstDayOfMonth = selected.startOf('month').weekday()
    }
  },
  filters: {
    dayString (value) {
      return moment().day(value).format('dddd')
    }
  }
}
</script>

<style lang="scss">
.flex-float-right {
    margin-left: auto;
}

.month {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #e6e6e6;
}

.day {
  flex-grow: 1;
  flex-basis: 13.28%;
  border: 1px solid #e6e6e6;
  height: 125px;
  border-left: 0;
  border-right: 0;

  &:hover {
    background-color: #e6e6e6;
  }

  &.padded {
    background-color: #f7f7f7;
  }
}

.day-header {
  height: 1.75rem;
}

.schedule {
  line-height: 1.25rem;
  mix-blend-mode: difference;
  text-align: center;
}
</style>
