<template lang="html">
    <b-row class="p-0 m-0">
      <b-col class="m-2">
        <multiselect :placeholder="'🔍 ' + $t('type to search')" :options="transformedTeams" track-by="teamId" label="name" v-model="selectedTeam" @input="getTeamID" :allow-empty="false"></multiselect>
      </b-col>
    </b-row>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      selectedTeam: {name: 'No Team Selected', teamId: 0 },
      selectedTeamId: 0,
    }
  },
  props: {
    resetTeamsTrigger: {
      type: Number,
      required: true
    },
  },
  methods: {
    getTeamID(obj) {
      this.selectedTeamId = obj ? obj.teamId : 0
    },
    getAuthTeams() {
      return Object.keys(this.$auth.user.teams).map((id => ({
            teamId: id,
            name: this.$auth.user.teams[id].name
      }))).sort(function(a,b){if(a.name.localeCompare(b.name) > 0){return 1} else {return -1}})
    },
    savedTeamExists(teams) {
      let maybeTeam = JSON.parse(localStorage.getItem('saved_team'))
      if (maybeTeam && teams.findIndex(t => (maybeTeam.teamId === t.teamId)) !== -1) {
        return maybeTeam
      }
      else {
        return false
      }
    }
  },
  computed: {
    transformedTeams: function() {
      // this is to force updating because $auth teams is not observable
      this.resetTeamsTrigger;
      return this.getAuthTeams()
    },
  },
  watch: {
    resetTeamsTrigger: function() {
      let teams = this.getAuthTeams()
      if (teams.length > 0) {
        let previously_saved = this.savedTeamExists(teams)
        if (previously_saved) {
          this.selectedTeam   = previously_saved
          this.selectedTeamId = previously_saved.teamId
        }
        else {
          this.selectedTeam   = teams[0]
          this.selectedTeamId = teams[0].teamId
        }
      }
      else {
        this.selectedTeam = { name: 'No Team Selected', teamId: 0 }
        this.selectedTeamId = 0
      }
    },
    selectedTeamId: function() {
      if (this.selectedTeamId === 0) {
        this.$emit('updateTeam', { name: 'No Team Selected', id: this.selectedTeamId })
      }
      else {
        this.$emit('updateTeam', { name: this.$auth.user.teams[this.selectedTeamId].name, id: this.selectedTeamId })
        localStorage.setItem('saved_team', JSON.stringify(this.selectedTeam))
      }
    },
  },
  mounted () {
  },
}
</script>

<style scoped lang="scss">

@import "node_modules/bootstrap/scss/_functions.scss";
@import "node_modules/bootstrap/scss/_variables.scss";
@import "node_modules/bootstrap/scss/mixins/_breakpoints.scss";

</style>
