
<template lang="html">
  <div class="d-flex flex-wrap justify-content-center">
    <div class="" :key="display.displayId" v-for="(display) in computedDisplays">
      <b-button variant="light" class="display m-1 p-2 border border-radius" v-b-modal="'display_' + display.displayId">
        <display-preview class="preview" :display="display" :refreshTime="refreshTime"></display-preview>
        <div :style="{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }"><small>{{ display.name }}</small></div>
      </b-button>
      <b-modal size="lg" no-fade hide-header hide-header-close hide-footer centered :id="'display_' + display.displayId">
        <display-dashboard :display="display"></display-dashboard>
      </b-modal>
    </div>
  </div>
</template>

<script>
import DisplayPreview from "./DisplayPreview.vue";
import DisplayDashboard from "@/pages/Displays/Dashboard/Dash.vue";
import extendedViewports from "@/mixins/extendedViewports";

export default {
  mixins: [extendedViewports],
  components: {
    DisplayDashboard,
    DisplayPreview,
  },
  props: {
    displays: {
      type: Array,
      required: true,
    },
    refreshTime: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      online: {},
      offline: {},
      stats: {},
      timer: {},
      currentRow: 0,
      isShowing: false,
      selectedDisplay: {},
    };
  },
  mounted() {
    this.getAvailable();
    this.getOffline();
    this.timer = setInterval(() => {
      this.getAvailable();
      this.getOffline();
    }, 10000);
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  computed: {
    computedDisplays() {
      return this.displays.filter((d) => this.online[d.hardwareId]);
    },
    viewportCols() {
      if (this.viewport === "xs") {
        return 12;
      } else if (this.viewport === "sm") {
        return 6;
      } else if (this.viewport === "md") {
        return 6;
      } else if (this.viewport === "lg") {
        return 4;
      } else if (this.viewport === "xl") {
        return 2;
      } else {
        return 12;
      }
    },
  },
  methods: {
    getAvailable() {
      this.$axios
        .get(process.env.VUE_APP_NETWORK_ENDPOINT + "clients", { headers: {} })
        .then(
          (response) => {
            this.online = response.data;
            this.queryDisplays();
          },
          () => {
            console.log("error?");
          }
        );
    },
    getOffline() {
      this.$axios
        .get(process.env.VUE_APP_NETWORK_ENDPOINT + "clients", { headers: {} })
        .then(
          (response) => {
            this.offline = response.data;
          },
          () => {}
        );
    },
    queryDisplays() {
      for (let display of this.displays) {
        this.queryDisplay(display);
      }
    },
    queryDisplay(display) {
      if (display.acid) {
        let url =
          process.env.VUE_APP_NETWORK_ENDPOINT +
          "collate?client=" +
          display.acid;
        this.$axios.get(url, { headers: {} }).then(
          (response) => {
            this.stats[display.hardwareId] = response.data;
          },
          () => {
            this.stats[display.hardwareId] = {};
          }
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "node_modules/bootstrap/scss/_functions.scss";
@import "node_modules/bootstrap/scss/_variables.scss";
@import "node_modules/bootstrap/scss/mixins/_breakpoints.scss";

.display {
  height: 20vh;
  width: 20vh;
}

.preview {
  height: calc(100% - 1em);
  background: #000;
}
</style>
