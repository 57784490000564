import Vue from 'vue'
import './plugins/bootstrap-vue'

// load any global Vue directives and filters
import '@/lib/filters'
import '@/lib/directives'

// replacement as per babel recommends
import "core-js/stable"
import "regenerator-runtime/runtime"

// can we get rid of these now?
import 'mutationobserver-shim'
import messages from '@/lang'
import moment from 'moment'
import 'vue-multiselect/dist/vue-multiselect.min.css'

import VueHtmlToPaper from 'vue-html-to-paper';

// I am not really happy about this, especially the bootstrap cdn stuff
const options = {
  // name: '_blank',
  specs: [
    'fullscreen=no',
    'titlebar=no',
    'scrollbars=no'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
  ]
}

Vue.use(VueHtmlToPaper, options);

// or, using the defaults with no stylesheet
Vue.use(VueHtmlToPaper);

// replace vue resorce with axios
import axios from 'axios'
Vue.prototype.$axios = axios.create({
  baseURL: process.env.VUE_APP_API_ROOT + '/api'
});


import router from './router'

// initialise Auth component and pass the router object for redirects, etc
import Auth from '@/lib/auth/auth'
Vue.use(Auth, {
  router: router
})

Vue.use(Notifications)
import Notifications from '@/lib/notifications/notifications'

// import Uploader from 'vue-uploader'
// Vue.use(Uploader, {url: process.env.VUE_APP_UPLOAD_ENDPOINT})

require('drag-drop-webkit-mobile')({holdToDrag: 200, enableEnterLeave: true})

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

import Cart from '@/lib/cart'
Vue.use(Cart)

import Icon from 'vue-awesome/components/Icon'
Vue.component('v-icon', Icon)

import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)


const i18n = new VueI18n({
  messages,
  locale: 'en'
})



import { LMap, LTileLayer, LMarker } from "vue2-leaflet"
Vue.component('v-map', LMap)
Vue.component('v-tilelayer', LTileLayer)
Vue.component('v-marker', LMarker)

//recommended kludge to add fallback items ;_;
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

//end of kludge

import App from './App.vue'

/* eslint-disable no-new */
new Vue({
  i18n,
  el: '#app',
  render: h => h(App),
  components: {
    App,
  },
  router,
  silent: false,
  errorHandler: function (error, vm, info) {
    console.log(error, vm, info)
  },
  warnHandler: function (error, vm, info) {
    console.log(error, vm, info)
  }
}).$mount('#app')

// apparently this needs to be down here for it to actually change the locale... go figure
let lang = localStorage.getItem('locale')
if (lang) {
  i18n.locale = lang
  moment.locale(lang)
} else {
  i18n.locale = 'en'
  moment.locale('en')
  localStorage.setItem('locale', 'en')
}