<template>
  <router-view :activeTeam="activeTeam"></router-view>
</template>

<style scoped lang="scss">
  .nav-item {
    cursor: pointer;
  }
</style>

<script>
export default {
  props: {
    activeTeam: {
      type: Object,
      required: true,
    },
  },
}
</script>
