<template>
  <b-container class="mt-3">
    <b-row class="mb-3">
      <b-col cols="12" lg="6">
        <b-card :header="$t('common.information')">
          <b-card-text>
            <dl>
              <dt>{{$t('common.name')}}</dt>
              <dd>{{ display.name }}</dd>
              <dt>{{$t('common.description')}}</dt>
              <dd>{{ display.description }}</dd>
              <dt>{{$t('displays.type')}}</dt>
              <dd>{{$t( computedScreenType )}}</dd>
              <dt>{{$t('displays.privacy')}}</dt>
              <dd>
                <span v-if="display.private == 1">{{$t('privacy.private')}}</span>
                <span v-else>{{$t('privacy.public')}}</span>
              </dd>
              <dt v-if="display.private == 0">{{$t('displays.publicUrl')}}</dt>
              <dd v-if="display.private == 0">{{ publicUrl }}</dd>
            </dl>
          </b-card-text>
        </b-card>
      </b-col>

      <b-col cols="12" lg="6">
        <b-card>
          <b-card-text>
            <display-gallery :displayId="display.displayId"></display-gallery>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col cols="12" lg="6">
        <b-card :header="$t('common.dimensions')">
          <b-card-text>
            <dl>
              <dt>{{$t('displays.physicalHeight')}}</dt>
              <dd>{{ display.physicalHeight }}</dd>
              <dt>{{$t('displays.physicalWidth')}}</dt>
              <dd>{{ display.physicalWidth }}</dd>
              <dt>{{$t('displays.pixelHeight')}}</dt>
              <dd>{{ display.pixelHeight }}</dd>
              <dt>{{$t('displays.pixelWidth')}}</dt>
              <dd>{{ display.pixelWidth }}</dd>
              <dt>{{$t('displays.rotation')}}</dt>
              <dd>{{ display.rotate }}</dd>
            </dl>
          </b-card-text>
        </b-card>
      </b-col>

      <b-col cols="12" lg="6">
        <b-card :header="$t('displays.location')">
          <b-card-text>
            <b-table-simple small borderless>
              <b-tr>
                <b-th>{{$t('displays.mobility')}}</b-th>
                <b-th>{{$t('displays.coordinates')}}</b-th>
              </b-tr>
              <b-tr>
                <b-td>
                  <span v-if="display.mobile == 1">{{$t('displays.mobile')}}</span>
                  <span v-else>{{$t('displays.stationary')}}</span>
                </b-td>
                <b-td>
                  Lat: {{display.latitude}}, Lng: {{display.longitude}} <b-button size="sm" variant="link"
                    @click="externalMap">Find on Google</b-button>
                </b-td>
              </b-tr>
            </b-table-simple>
            <div class="map">
              <v-map :zoom="13" :center="[display.latitude, display.longitude]">
                <v-tilelayer :options="{ maxZoom: 13 }" :url="mapUrl"></v-tilelayer>
                <v-marker :lat-lng="[display.latitude, display.longitude]" :icon="icon"></v-marker>
              </v-map>
            </div>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col cols="12" lg="6">
        <b-card :header="$t('displays.timezoneCurrency')">
          <b-card-text>
            <dl>
              <dt>{{$t('displays.timezone')}}</dt>
              <dd>{{ display.timezone }}</dd>
              <dt>{{$t('displays.currency')}}</dt>
              <dd>{{$t('currency.' + display.currency + '')}}</dd>
            </dl>
          </b-card-text>
        </b-card>
      </b-col>

      <b-col cols="12" lg="6" v-if="display.tags">
        <b-card :header="$t('common.tags')">
          <b-card-text>
            <dl>
              <dd>
                <span v-for="tagData in display.tags.data" v-bind:key="tagData.id">
                  {{ tagData.tag }}
                  <br />
                </span>
              </dd>
            </dl>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mb-3">
      <b-col cols="12" lg="12">
        <b-card :header="$t('displays.brightness')">
          <b-card-text>
            <dl>
              <dt>{{$t('displays.brightnessControl')}}</dt>
              <dd>{{$t('brightnessControl.' + display.brightnessControl + '')}}</dd>
              <dt
                v-show="display.brightnessControl === 'scheduled'"
              >{{$t('displays.brightnessCurve')}}</dt>
              <dd v-if="display.brightnessControl === 'scheduled'">
                <curve
                  v-model="display.brightnessCurve"
                  :inputs="false"
                  v-if="display.brightnessControl === 'scheduled'"
                ></curve>
              </dd>
            </dl>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col cols="12" lg="12">
        <b-card :header="$t('common.admin')">
          <b-card-text>
            <dl>
              <dt>{{$t('displays.acid')}}</dt>
              <dd><a :href="computedAcid">{{display.acid}}</a></dd>
              <dt>{{$t('displays.hwid')}}</dt>
              <dd><a :href="computedHwid">{{display.hardwareId}}</a></dd>
              <dt>{{$t('common.approval')}}</dt>
              <dd>{{$t('approval.' + display.approval + '')}}</dd>
              <dt>{{$t('displays.demoMode')}}</dt>
              <dd>{{$t('truth.' + display.demoMode + '')}}</dd>
            </dl>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Curve from "./Components/BrightnessCurve.vue";
import DisplayGallery from "@/components/DisplayGallery.vue";
import L from "leaflet";
require("leaflet/dist/leaflet.css");

export default {
  name: "ViewDisplay",
  components: {
    Curve,
    DisplayGallery
  },
  props: {
    display: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      mapUrl: process.env.VUE_APP_MAP_PROVIDER_URL,
      icon: {}
    };
  },
  computed: {
    computedAcid() {
      return process.env.VUE_APP_API_ROOT + '/api/getSchedule/' + this.display.acid
    },
    computedHwid() {
      return process.env.VUE_APP_API_ROOT + '/api/getConfig/' + this.display.hardwareId
    },
    publicUrl() {
      return (
        process.env.VUE_APP_PUBLIC_URL + "d/" + this.display.slug
      );
    },
    computedScreenType() {
      return 'screenType.' + this.display.type.replace(' ', '') + ''
    },
  },
  mounted() {
      this.icon = L.icon({
        iconUrl: require("@/assets/pin.png"),
        iconSize: [30, 45],
        iconAnchor: [0, 42],
        popupAnchor: [15, -20]
      });
    },
    methods: {
      externalMap() {
        window.open('//maps.google.com/maps?ll=' + this.display.latitude + ',' + this.display.longitude + '&q=' + this.display.latitude + ',' + this.display.longitude, '_blank')
      }
    }
};
</script>

<style scoped>
.map {
  width: 100%;
  height: 250px;
}
</style>
