var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-modal',{attrs:{"id":"newdisplay","title":_vm.$t('displays.newDisplay'),"size":"lg","hide-footer":""}},[(_vm.activeTeam.id != 0)?_c('new-display',{attrs:{"activeTeam":_vm.activeTeam},on:{"refreshDisplays":_vm.savedUpdate}}):_vm._e()],1),_c('b-row',{staticClass:"header mt-3 pb-3 w-100",attrs:{"id":"top-area"}},[_c('b-col',[_c('h2',[_vm._v(_vm._s(_vm.$t("displays.displays")))]),_c('p',{staticClass:"d-none d-md-block"},[_vm._v(_vm._s(_vm.$t("tutorial.displaysExample")))])]),_c('b-col',{staticClass:"d-md-none"},[_c('multiselect',{attrs:{"options":_vm.filtered,"track-by":"displayId","label":"name","value":_vm.compSelected,"searchable":false,"allow-empty":false},on:{"select":_vm.selectDisplay}})],1),_c('b-col',{staticClass:"d-md-none",attrs:{"cols":"1"}},[_c('div',{on:{"click":function($event){return _vm.newDisplay()}}},[_c('v-icon',{attrs:{"name":"plus","scale":"1.5","label":"New Display"}})],1)])],1),(_vm.loading)?_c('b-row',{attrs:{"id":"content-area"}},[_c('div',{staticClass:"spinner"})]):_c('b-row',{attrs:{"id":"content-area"}},[_c('b-col',{ref:"sidebar",staticClass:"d-none d-md-block h-100 overflow list",attrs:{"md":"4","lg":"2"}},[_c('b-form-input',{staticClass:"search",attrs:{"id":"search","placeholder":_vm.$t('actions.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{staticClass:"new-item item",on:{"click":function($event){return _vm.newDisplay()}}},[_c('v-icon',{attrs:{"name":"plus","scale":"1.5","label":"New Display"}})],1),_vm._l((_vm.filtered),function(display){return _c('div',{key:display.id,staticClass:"pt-3 pb-3 item",class:{
          selected:
            _vm.compSelected !== {} &&
            _vm.compSelected.displayId === display.displayId,
        },attrs:{"id":'d_' + display.displayId,"title":_vm.clients[display.hardwareId]
            ? 'Online'
            : 'Offline (last connected: ' + display.lastConnected + ')'},on:{"click":function($event){return _vm.selectDisplay(display)}}},[_c('p',{staticClass:"mb-0"},[(_vm.clients[display.hardwareId])?[_c('b-icon',{staticClass:"bg-white rounded-circle",attrs:{"animation":"","variant":"success","icon":"emoji-smile"}})]:[_c('b-icon',{staticClass:"bg-white rounded-circle",attrs:{"animation":"","variant":"danger","icon":"emoji-frown"}})],_vm._v(" "+_vm._s(display.name)+" ")],2),_c('div',{staticClass:"text-muted"},[_c('small',[_vm._v(_vm._s(display.pixelWidth == 0 ? "(LCD native)" : ((display.pixelWidth) + "x" + (display.pixelHeight) + " px")))])]),(_vm.$auth.user.admin)?_c('div',{staticClass:"text-muted"},[_c('small',[_vm._v(_vm._s(display.hardwareId))])]):_vm._e(),(_vm.$auth.user.admin)?_c('div',{staticClass:"text-muted"},[_c('small',[_vm._v(_vm._s(display.acid))])]):_vm._e()])})],2),_c('b-col',{staticClass:"h-100 overflow",attrs:{"cols":"12","md":"8","lg":"10"}},[_c('transition',{attrs:{"name":"fade"}},[(
            _vm.filtered.length > 0 &&
            _vm.$route.params.displayId &&
            _vm.compSelected !== {}
          )?_c('router-view',{staticClass:"h-100",attrs:{"display":_vm.compSelected,"activeTeam":_vm.activeTeam},on:{"delete":_vm.fetchDisplays,"refreshDisplays":_vm.refreshDisplays}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }