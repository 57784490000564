<template lang="html">
  <b-col cols="10" md="4" lg="2" class="sidebar d-none d-md-block h-100" v-bind:class="{visible: active}" v-if="user.authenticated">
    <b-row class="p-0 m-0">
      <b-col class="pt-2 m-0">
        <h6 class="m-0 text-muted text-center">{{$t('dashboard.headers.activeTeam')}}</h6>
      </b-col>
    </b-row>
    <team-selector @updateTeam="$emit('updateTeam', $event)" :resetTeamsTrigger="resetTeamsTrigger" />
    <ul class="accordion-menu pl-2">
      <li v-for="(link, index) in filterLinks" :key="index" :class="{'has-children': link.children}">
        <input v-if="link.children" type="checkbox" :name="'group-'+index" :id="'group-'+index">
        <label v-if="link.children" :for="'group-'+index" class="sidebar-link ">
          <v-icon :name="link.icon" scale="1" class="icon mr-2" />
          <span>{{ $t(link.key) }}</span>
        </label>

        <ul v-if="link.children" @click="$emit('hide')">
          <router-link tag="li" :to="child.uri" v-for="(child, childIndex) in link.children" :key="childIndex" class="sidebar-link child-link">
            <v-icon :name="child.icon" scale="1" class="icon mr-2" />
            <span>{{ $t(child.key) }}</span>
          </router-link>
        </ul>

        <ul v-else  @click="$emit('hide')">
          <router-link class="sidebar-link" :to="link.uri" tag="li">
            <v-icon :name="link.icon" scale="1" class="icon mr-2" shape-rendering="geometricPrecision" />
            <span>{{ $t(link.key) }}</span>
          </router-link>
        </ul>
      </li>
    </ul>
  </b-col>
</template>

<script>
import TeamSelector from '@/components/TeamSelector.vue'

export default {
  components: {
    TeamSelector,
  },
  data() {
    return {
    }
  },
  props: {
    active: {
      type: Boolean,
      required: true
    },
    resetTeamsTrigger: {
      type: Number,
      required: true
    },
    links: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true
    }
  },
  methods: {
  },
  computed: {
    filterLinks: function() {
      this.resetTeamsTrigger;
      if (!this.user.admin) {
        return this.links.filter(i => !i.admin)
      }
      else {
        return this.links
      }
    },
  },
  watch: {
  },
  mounted () {
  },
}
</script>

<style scoped lang="scss">
.icon {
  color: black;
}

@import "node_modules/bootstrap/scss/_functions.scss";
@import "node_modules/bootstrap/scss/_variables.scss";
@import "node_modules/bootstrap/scss/mixins/_breakpoints.scss";

.accordion-menu input[type=checkbox] {
  position: absolute;
  opacity: 0;
}

.accordion-menu label, .accordion-menu a {
  position: relative;
}

/** Hide all submenus */
.accordion-menu .has-children ul li {
  visibility: hidden;
  height: 0;
  transition: visibility 0.3s ease-out 0.3, height ease-out 0.3s;
}

.accordion-menu .has-children ul {
  visibility: hidden;
  height: 0;
  transition: visibility 0s ease-out 0.3s, height ease-out 0.5s;
}

/** Show when input is checked */
.accordion-menu .has-children input[type=checkbox]:checked + label + ul li{
  visibility: visible;
  height: 3.5rem;
  transition: visibility 0s ease-in 0.3s, height ease-in 0.3s;
  display: flex;
  align-items: center;
}

.accordion-menu .has-children input[type=checkbox]:checked + label + ul {
  visibility: visible;
  height: auto;
  transition: visibility 0s ease-in 0.3s, height ease-in 0.3s;
}

.accordion-menu .has-children input[type=checkbox] {
  height: 0;
  width: 0;
}

.sidebar {
  display: block;
  padding: 0 0 0 0;
  z-index: 1020;
  background-color: #f5f5f5;
  border-right: 1px solid #eee;
  box-shadow: 5px 0 15px -7px #aaaaaa;
  left: -83.333333%;
  transition: left 0.3s linear;
  position: fixed;
  overflow-y: auto;
}

.sidebar.visible {
  left: 0;
  transition: left 0.3s linear;
}

.sidebar ul {
  list-style: none;
  padding-left: 0;
}

.sidebar-link {
  cursor: pointer;
  line-height: 3.5rem;
  padding-top: .425rem;
  padding-bottom: .425rem;
  padding-left: 20px;
  display: block;
  margin-bottom: 0;
  border-bottom: solid 1px rgba(189, 195, 199, 0.30);

  &:hover, &.router-link-active {
    background-color: #e6e6e6;
  }
}

.child-link {
  padding-left: 40px;
}

@include media-breakpoint-up(lg) {
  .sidebar {
    left: 0;
    position: relative;
  }
}
</style>
