<template>
<b-container>
  <h2>Coming Soon</h2>
  <b-button @click="$router.push({ path: '/' })">Back</b-button>
</b-container>
</template>

<script>


export default {
  components: {

}
}
</script>

<style lang="scss">

</style>
