<template lang="html">
  <div v-if="isShowing(this.text)">
    <span class="text-item" :style="{ fontSize: this.text.fontSize, fontFamily: this.text.fontFamily, fontStyle: this.text.fontStyle, fontWeight: this.text.fontWeight }">
      {{ this.text.string }}
    </span>
  </div>
</template>

<script>
import showing from '../../mixins/show.js'

export default {
  props: {
    text: {
      type: Object,
      required: true
    },
    time: {
      type: Number,
      required: true
    },
    playing: {
      type: Boolean,
      default () {
        return true
      }
    }
  },
  mixins: [
    showing
  ]
}
</script>

<style lang="scss">
@import 'positions.scss';

.text-item {
  color: white;
}
</style>
