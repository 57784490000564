<template>
  <b-container fluid>
    <b-row class="header mt-3 pb-3 w-100" id="top-area">
      <b-col>
        <h2>{{$t('user.users')}}</h2>
        <p class="d-none d-md-block">{{$t('tutorial.usermanagementExample')}}</p>
      </b-col>
      <b-col class="d-md-none">
        <multiselect :options="filteredUsers" track-by="userId" label="username" @select="selectUser"
          :value="compSelected" :searchable="false" :allow-empty="false"></multiselect>
      </b-col>
    </b-row>

    <b-row v-if="loading" id="content-area">
      <div class="spinner">
      </div>
    </b-row>

    <b-row v-else id="content-area">
      <b-col md="4" lg="2" class="d-none d-md-block h-100 overflow list">
        <b-form-input id="search" class="search" :placeholder="$t('actions.search')" v-model="search"></b-form-input>
        <div :key="user.userId" v-for="user in filteredUsers" @click="selectUser(user)" class="pb-3 pt-3 item"
          :class="{ selected: compSelected.userId === user.userId }">
          {{user.username}}
        </div>
      </b-col>

      <b-col cols="12" md="8" lg="10" class="h-100 overflow">
        <transition name="fade">
          <router-view v-if="filteredUsers.length > 0 && $route.params.userId && compSelected !== {}"
            :user="compSelected" @delete="fetchUsers" @refresh="fetchUsers">
          </router-view>
        </transition>
      </b-col>

    </b-row>
  </b-container>
</template>

<style scoped lang="scss">
.header {
  display: inline-flex;
  background-color: white;
  z-index: 100;
  border-bottom: 1px #e9e9e9 solid;
}

.item:hover,
.selected {
  background-color: #e6e6e6;
}

.item {
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.25;
  padding-top: .425rem;
  padding-bottom: .425rem;
  padding-left: 20px;
  display: block;
  border-bottom: #e9e9e9 solid 1px;
}

.overflow {
  overflow-y: auto;
  display: block;
}

.search {
  width: 100%;
  height: 54px;
  border: none;
  text-align: center;
  border-bottom: 1px #e9e9e9 solid;
}

.new-item {
  padding-left: 0;
  text-align: center;
}

.list {
  border-right: #e9e9e9 solid 1px;
  padding: 0px;
}

.spinner {
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}
</style>

<script>
import 'vue-awesome/icons/arrow-left'
import _ from 'lodash'
import verge from 'verge'
import Multiselect from 'vue-multiselect'

export default {
  name: 'Users',
  components: {
    Multiselect
  },
  data() {
    return {
      users: [],
      show: false,
      search: '',
      loading: true
    }
  },
  computed: {
    filteredUsers() {
      // consider sorting by user.username here - before filtering
      return _.sortBy(this.users.filter((user) => {
        if (this.search === '') {
          return true
        } else {
          return user.username.toLowerCase().includes(this.search.toLowerCase())
        }
      }), function (i) {
        return i.username.toLowerCase()
      })
    },
    compSelected() {
      let selected = this.users.find((user) => {
        if (user.userId === parseInt(this.$route.params.userId)) {
          return true
        } else {
          return false
        }
      })
      if (selected) {
        return selected
      } else
        return {}
    }
  },
  watch: {
    filteredUsers() {
      this.updateRoute()
    }
  },
  mounted() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
    this.fetchUsers()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize: _.debounce(
      function () {
        let toparea = document.getElementById('top-area')
        let navbar = document.getElementById('navbar')
        let topheight = verge.rectangle(toparea).height
        let navbarheight = verge.rectangle(navbar).height
        let newheight = parseInt(verge.viewportH() - topheight - navbarheight) + 'px'
        document.getElementById('content-area').style.height = newheight
      }, 200),
    fetchUsers() {
      var scope = this
      this.loading = true
      this.$axios.get('v1/users?limit=all').then(
        (response) => {
          this.users = response.data.data.reverse()
          this.loading = false
        }).catch(
        function (error) {
          if (!error.response) {
            scope.$notifications.add({
              title: 'Error',
              type: 'warning',
              body: error.message,
              timeout: true
            })
          } else if (error.response.status !== 404) {
            scope.$notifications.add({
              title: 'Error',
              type: 'warning',
              body: error.response.data.message,
              timeout: true
            })
          }
          scope.loading = false
        }
      )
    },
    selectUser(user) {
      this.$router.push({
        name: 'user-view',
        params: {
          userId: user.userId
        }
      })
    },
    updateRoute() {
      if (this.filteredUsers.length > 0) {
        let maybeId = this.$route.params?.userId
        if (maybeId !== undefined) {
          let maybeUser = this.filteredUsers.find(u => u.userId == maybeId)
          if (maybeUser !== undefined) {
            return this.$router.replace({
              name: 'user-view',
              params: {
                userId: maybeId
              }
            })
          } else {
            return this.$router.replace({
              name: 'users'
            })
          }
        }
        return this.$router.replace({
          name: 'user-view',
          params: {
            userId: this.filteredUsers[0].userId
          }
        })
      }
    }
  }
}
</script>
