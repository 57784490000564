<template lang="html">
  <b-row>
    <b-col cols="12">
        <createNews class="col-12"></createNews>
    </b-col>
  </b-row>
</template>

<script>
import createNews from './createNews'

export default {
  components: {
    createNews
  }
}
</script>

<style lang="css">
</style>
