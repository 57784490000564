import { render, staticRenderFns } from "./Displays.vue?vue&type=template&id=06d93887&scoped=true&"
import script from "./Displays.vue?vue&type=script&lang=js&"
export * from "./Displays.vue?vue&type=script&lang=js&"
import style0 from "./Displays.vue?vue&type=style&index=0&id=06d93887&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06d93887",
  null
  
)

export default component.exports