<template lang="html">
  <div>
    <canvas :id="chartId" :width="width" :height="height" ref="canvas">
    </canvas>
    <div class="input-values" ref="inputs" v-if="inputs">
      <input class="input-value text-center" :key="key" v-for="(val, key) in value" type="number" v-model="value[key]" min="0" max="100">
    </div>
    <!-- <div class="form-group">
      <label for="newTime"></label>
      <input id="newTime" type="number" min="0" max="23" v-model="newTime">
      <button type="button" name="button" class="btn btn-primary" @click="addTime()">+</button>
    </div> -->
  </div>
</template>

<style lang="css">
.input-values {
  display: flex;
  justify-content: space-between;
}
.input-value {
  max-width: calc(100%/5);
}
</style>
<script>
import Chart from 'chart.js'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    inputs: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  mounted () {
    this.updateChartData()
    this.renderChart()
    this.$emit('input', this.value)
  },
  data () {
    return {
      chartId: {
        default: 'line-chart',
        type: String
      },
      width: {
        default: 400,
        type: Number
      },
      height: {
        default: 400,
        type: Number
      },
      newTime: 0,
      options: {
        title: {
          display: false
        },
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              max: 100,
              min: 0
            }
          }]
        }
      },
      chartData: {}
    }
  },
  methods: {
    updateChartData () {
      this.chartData = {
        labels: Object.keys(this.value),
        datasets: [
          {
            backgroundColor: '#f87979',
            data: Object.values(this.value)
          }
        ]
      }
    },
    updateChart () {
      this.chartData.datasets.forEach((dataset, i) => {
        this._chart.data.datasets[i].data = dataset.data
      })

      this._chart.data.labels = this.chartData.labels
      this._chart.update()
    },
    addTime () {
      this.value[this.newTime] = 100
      this.updateChartData()
      this.updateChart()
    },
    renderChart () {
      this._chart = new Chart(
        this.$refs.canvas.getContext('2d'), {
          type: 'line',
          data: this.chartData,
          options: this.options
        }
      )
      this._chart.generateLegend()
    }
  },
  beforeDestroy () {
    this._chart.destroy()
  },
  watch: {
    'value': {
      handler () {
        this.updateChartData()
        this.updateChart()
        this.$emit('input', this.value)
      },
      deep: true
    }
  }
}
</script>
