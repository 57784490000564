<template lang="html">
  <div>
    <div class="col-12 alert alert-danger" v-if="errors.length > 0">
      <ul>
        <li v-bind:key="index" v-for="(error, index) in errors">
          {{ error }}
        </li>
      </ul>
    </div>
    <div v-on:keyup.enter.prevent.stop="createSchedule()">
      <b-row>
        <b-col>
          <b-form-group :label="$t('common.name')" label-for="name">
            <b-form-input id="name" :placeholder="$t('common.name')" v-model="newSchedule.scheduleName">
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group :label="$t('displayPricing.priority')" label-for="priority">
            <b-form-input id="priority" type="number" max="100" min="1" v-model="newSchedule.priority"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-card class="mb-3 mt-3" header="Schedule Duration">
            <b-card-text>
              <b-form-group :label="$t('dateTime.sDate')" label-for="startDate">
                <flatpickr id="startDate" :placeholder="$t('dateTime.date')" :options="flatpickrOptions.dateDefaults"
                  v-model="newSchedule.startDate"></flatpickr>
              </b-form-group>
              <b-form-group :label="$t('dateTime.eDate')" label-for="endDate">
                <flatpickr id="endDate" :placeholder="$t('dateTime.date')" :options="flatpickrOptions.dateDefaults"
                  v-model="newSchedule.endDate"></flatpickr>
              </b-form-group>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col cols="6">
          <b-card class="mb-3 mt-3" header="Daily Price Times">
            <b-card-text>
              <b-form-group :label="$t('dateTime.sTime')" label-for="startTime">
                <b-dropdown no-caret ref="startDropdown" id="startTime" class="w-100" variant="outline-secondary">
                  <template v-slot:button-content>
                    <div class="text-left">
                      {{newSchedule.startTime}}
                    </div>
                  </template>
                  <b-button-group v-for="n in 4" v-bind:key="n.id" size="sm" class="ml-2 mr-2">
                    <b-button v-for="i in 6" v-bind:key="i.id" class="timebutton"
                      @click="newSchedule.startTime=(((n-1)*6)+(i-1)).toString().padStart(2,'0')+':00:00';$refs.startDropdown.hide(true)">
                      {{((n-1)*6)+(i-1)}}:00</b-button>
                  </b-button-group>
                </b-dropdown>
              </b-form-group>
              <b-form-group :label="$t('dateTime.eTime')" label-for="endTime">
                <b-dropdown no-caret ref="endDropdown" id="endTime" class="w-100" variant="outline-secondary">
                  <template v-slot:button-content>
                    <div class="text-left">
                      {{newSchedule.endTime}}
                    </div>
                  </template>
                  <b-button-group v-for="m in 4" v-bind:key="m.id" size="sm" class="ml-2 mr-2">
                    <b-button v-for="o in 6" v-bind:key="o.id" class="timebutton"
                      @click="newSchedule.endTime=(((m-1)*6)+(o)).toString().padStart(2,'0')+':00:00';$refs.endDropdown.hide(true)">
                      {{((m-1)*6)+(o)}}:00</b-button>
                  </b-button-group>
                </b-dropdown>
              </b-form-group>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <hr>
      <pricing-calculator v-model="newSchedule.pricePerSecond" :blockTime="display.blockTime"
        :maxTimePurchasable="display.maxTimePurchasable"></pricing-calculator>
      <b-button class="float-right mt-1" @click="createSchedule()" variant="primary">{{$t('buttons.save')}}</b-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Flatpickr from '@/components/Flatpickr.vue'
import PricingCalculator from "../Components/PricingCalculator.vue";

export default {
  components: {
    Flatpickr,
    PricingCalculator
  },
  props: {
    display: {
      type: Object,
      required: true
    },
    defaultSchedule: {
      type: Object,
      required: true
    }
  },
  mounted () {
    // Vue.$nextTick(this.calcBase()) To Fix
  },
  data () {
    return {
      newSchedule: {
        startTime: '00:00:00',
        endTime: '23:59:59'
      },
      flatpickrOptions: {
        dateDefaults: {
          disableMobile: true,
          allowInput: true
        },
        timeDefaults: {
          enableTime: true,
          noCalendar: true,
          disableMobile: true,
          hourIncrement: 1,
          minuteIncrement: 60,
          time_24hr: true,
          allowInput: true,
          dateFormat: 'H:i:00:00',
          altInput: true,
          altFormat: 'H:i'
        }
      },
      pricingSchedules: [],
      validation: true,
      errors: []
    }
  },
  watch: {
  },
  methods: {
    createSchedule () {
      if (this.newSchedule.endTime === "24:00:00") {
        this.newSchedule.endTime="23:59:59"
      }
      this.validateMe(this.newSchedule)
      if (this.validation) {
        this.newSchedule.displayDefault = false
        this.$axios.post('v1/displays/' + this.$route.params.displayId + '/pricing', this.newSchedule).then(
          () => {
            this.newSchedule = {}
            this.$emit('close')
            this.$emit('refresh')
            this.$notifications.add({
              title: 'Created',
              type: 'success',
              body: this.$t('notifications.pricingScheduleCreate'),
              timeout: true
            })
          },
          () => {
            this.$notifications.add({
              title: 'Error',
              type: 'danger',
              body: this.$t('notifications.pricingScheduleCreateError'),
              timeout: true
            })
          }
        )
      }
    },
    validateMe (pricing) {
      this.validation = true
      this.errors = []
      this.stringTest(pricing.scheduleName, 'name', 255)
      this.validateDateTime(pricing.startDate, pricing.endDate, pricing.startTime, pricing.endTime)
      this.integerTest(pricing.priority, 'Priority')
      this.lengthTest(pricing.pricePerSecond, 'Priority', 0)
    },
    validateDateTime (startDate, endDate, startTime, endTime) {
      if (!moment(startDate).isBefore(endDate) || !startDate===endDate) {
        this.errors.push('Start date must be before the end date')
        this.validation = false
      }
      if (!moment(startTime, 'H:m:s').isBefore(moment(endTime, 'H:m:s'))) {
        this.errors.push('Start time must be before the end time')
        this.validation = false
      }

      if (!this.validateDate(startDate)) {
        this.errors.push('Invalid Start Date')
        this.validation = false
      }

      if (!this.validateDate(endDate)) {
        this.errors.push('Invalid End Date')
        this.validation = false
      }

      if (!this.validateTime(startTime)) {
        this.errors.push('Invalid Start Time')
        this.validation = false
      }

      if (!this.validateTime(endTime)) {
        this.errors.push('Invalid End Time')
        this.validation = false
      }
    },
    validateDate (date) {
      var DateRE = /^\d{4}[./-]\d{2}[./-]\d{2}$/
      return (DateRE.test(date))
    },
    validateTime (time) {
      var TimeRE = /^(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/
      return (TimeRE.test(time))
    },
    stringTest (value, input, max) {
      if (!value) {
        this.errors.push(input + ' is a required input')
        this.validation = false
      } else if (value.length > max) {
        this.errors.push(input + ' can only be a maxiumum of ' + max + ' characters')
        this.validation = false
      }
    },
    lengthTest (value, input, min, max) {
      if (value === undefined) {
        this.errors.push(input + ' is a required input')
        this.validation = false
        return
      }
      if (min !== undefined) {
        if (value < min) {
          this.errors.push(input + ' has to be a minimum of ' + min)
          this.validation = false
          return
        }
      }
      if (max !== undefined) {
        if (value > max) {
          this.errors.push(input + ' can only be a maximum of ' + max)
          this.validation = false
          return
        }
      }
    },
    integerTest (value, input) {
      if (value % 1 !== 0) {
        this.errors.push(input + ' can only be a whole number')
        this.validation = false
      }
    }
  }
}
</script>

<style lang="scss">
.button-textbox {
  width: 100%;
  text-align: center;
  outline-color: rgb(206, 212, 218);
  color: white;
}
</style>
