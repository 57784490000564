<template lang="html">
<b-row>
  <b-modal centered id="preview" size="md" hide-footer>
    <div class="p-2" :style="{ width: '100%', height: '30vh', resize: 'both' }">
      <player :media="computedMedia" :showControls="true" :style="{ width: '100%', height: '100%' }"></player>
    </div>
  </b-modal>
  <b-col v-if="computedMobile" cols="12" class="text-center m-3">
    <b-button class="m-2" variant="primary" @click="isMediaLibrary(false)">Playlist</b-button>
    <b-button class="m-2" variant="primary" @click="isMediaLibrary(true)">Media Library</b-button>
  </b-col>

  <b-col v-if="showLibrary" cols="12" md="6" id="mediaCol" :style="computedMediaStyle">
    <b-modal id="upload" :title="$t('media.create')" no-close-on-backdrop no-fade hide-footer>
      <new-media @updated="fetchMedia" @finished="$bvModal.hide('upload')" :activeTeam="activeTeam"></new-media>
    </b-modal>
    <b-row>
      <b-col class="mx-auto text-center p-0 m-0 mb-1 pb-1">
        <input type="text" :placeholder="$t('actions.search')" v-model="searchComputed">
        <b-button class="ml-4" type="button" variant="primary" size="sm" @click="$bvModal.show('upload')">{{$t('buttons.upload')}}</b-button>
      </b-col>
    </b-row>
    <div class="media-index h-100 d-flex flex-wrap align-content-start border p-1">
      <div class="spinner" v-if="loading"></div>
      <div
        v-else
        overlay
        v-for="(mediaItem) in filteredMedia"
        :key="mediaItem.mediaId"
        class="w-25"
        @click="selectMedia(mediaItem)"
        :id="mediaItem.mediaId"
      >
        <b-img fluid-grow center :src="getThumbnail(mediaItem.hash, 's')" class="media-item" :title="makeTitle(mediaItem)">
          <div class="duration" v-if="mediaItem.videoId">
            <span>{{mediaItem.duration | time}}</span>
          </div>
        </b-img>
      </div>
    </div>
  </b-col>

  <b-col v-if="showCampaignList" cols="12" md="6" :style="computedMediaStyle">
    <!-- the editor zone -->
      <h6 class="text-center">Drag the arrow icon or thumbnail to re-order.</h6>
      <b-list-group v-if="previewMedia.length == 0">
        <b-list-group-item class="text-center">
          No media added! try clicking on a thumbnail.
        </b-list-group-item>
      </b-list-group>
      <b-list-group v-else>

        <draggable tag="ul" :list="previewMedia" class="list-group" handle=".handle" ghost-class="ghost">
          <b-list-group-item
            class="pr-2"
            v-for="(mediaItem, index) in previewMedia"
            :key="index"
          >
          <!-- <b-badge variant="muted" rounded class="border-dark text-monospace m-1">{{ index + 1 }}</b-badge> -->
            <div class="d-flex align-items-center justify-content-between">
              <div class="">
                <div class="text-muted text-center" :title="mediaItem.name">
                  <small>{{ mediaItem.name }}</small>
                </div>
                <div class="d-flex align-items-center justify-content-center mt-2">
                  <div class="">
                    <b-img rounded class="w-100 handle" :src="getThumbnail(mediaItem.hash, 's')" alt=""></b-img>
                    <!-- <b-img rounded class="w-100 handle" :src="getThumbnail(mediaItem.hash, 's')" alt="" @contextmenu.prevent></b-img> -->
                  </div>
                  <div class="ml-2">
                    <div class="d-flex justify-content-around ml-2 mr-2">
                      <b-form-group invalid-feedback="duration can't be nothing!" :state="parseInt(mediaItem.duration) > 0">
                        <b-input-group class="" size="sm" prepend="play for" append="secs">
                          <b-form-input :disabled="mediaItem.mimeType.includes('video')" type="text" :value="mediaItem.duration" @input="setDuration(index, $event)" placeholder="duration:"></b-form-input>
                        </b-input-group>
                      </b-form-group>
                      <b-button variant="outline-danger" size="sm" squared class="h-100 ml-2" @click="deleteMedia(index)">Delete</b-button>
                      <b-icon variant="muted" icon="arrows-move" class="m-2 ml-4 p-1 handle" scale=4></b-icon>

                    </div>
                    <div class="text-center ml-2 mr-2">
                      <hr class="mb-0 mt-2 p-0" />
                      <small class="text-muted">file details:</small> 
                      <!-- <b-badge v-if="mediaItem.mimeType.includes('image')" variant="light">image</b-badge>
                      <b-badge v-else variant="light">video</b-badge> -->
                      <b-badge variant="light" class="m-1">{{ mediaItem.width }}x{{ mediaItem.height }}</b-badge>
                      <b-badge variant="light" class="m-1">{{ mediaItem.size | humanFileSize }}</b-badge>
                      <b-badge v-if="mediaItem.videoDuration" variant="light" class="m-1">{{ mediaItem.videoDuration }} seconds</b-badge>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-list-group-item>
        </draggable>
      </b-list-group>
      <div class="m-2">
      <b-button variant="success" :disabled="computedInvalid" @click="save()">{{$t('buttons.saveCampaign')}}</b-button>
      <b-button variant="danger" class="ml-2" v-b-modal.preview>Show Preview</b-button>
      </div>
  </b-col>
</b-row>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */

import Player from '@/components/Player/PropPlayer.vue'
import _ from 'lodash'
import 'vue-awesome/icons/trash'
import 'vue-awesome/icons/chevron-down'
import verge from 'verge'
import NewMedia from '@/pages/Media/Components/NewMedia.vue'
import thumbnails from '@/mixins/thumbnails'
import draggable from 'vuedraggable'

export default {
  mixins: [thumbnails],
  components: {
    NewMedia,
    Player,
    draggable,
  },
  props: {
    activeTeam: {
      type: Object,
      required: true,
    },
    campaign: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      search: '',
      dragging: false,
      previewMedia: [],
      selected: {},
      selectedFrame: {media: []},
      time: 0,
      height: 0,
      midCenter: false,
      viewport: '',
      media: [],
      trigger: 0,
      loading: true,
      showLibrary: true,
      showCampaignList: true,
    }
  },
  computed: {
    computedMedia() {
      this.trigger;
      let counter = 0
      return this.previewMedia.map(m => {
        let r = Object.assign({}, m)
        r.start = counter
        r.end = counter + parseInt(r.duration)
        counter = r.end
        return r
      })
    },
    computedInvalid() {
      this.trigger;
      return this.previewMedia.some(m => !parseInt(m.duration) > 0)
    },
    computedPreviewStyle() {
      if (this.viewport === 'xs') {
        return { 'height': '40vh' }
      }
      else {
        return { 'height': '50vh' }
      }
    },
    computedMediaStyle() {
      if (this.viewport === 'xs') {
        return { 'height': '70vh', 'overflow-y': 'scroll' }
      }
      else {
        return { 'height': '60vh', 'overflow-y': 'scroll' }
      }
    },
    colBreak() {
      if (this.viewport === 'xs') {
        return 12
      }
      else {
        return 6
      }
    },
        searchComputed: {
      get() {
        return this.search
      },
      set: _.debounce(function(newValue) {
        this.search = newValue
      }, 100)

    },
    filteredMedia() {
      return this.media.filter((item) => {
        if (item.teamId == this.activeTeam.id) {
          if (this.search === '') {
            return true
          } else {
            if (item.name) {
              return item.name.toLowerCase().includes(this.search.toLowerCase())
            }
            if (item.string) {
              return item.string.toLowerCase().includes(this.search.toLowerCase())
            }
          }
        }
      })
    },
  computedMobile(){
      if (this.viewport === 'xs') {
        this.isMobile(true)
        return true
      }else{
        this.isMobile(false)
        return false
      }
  },

  },
  mounted () {
    this.trigger++
    if('media' in this.campaign) {
    this.previewMedia = this.campaign.media.data.map( c => {
      c.duration = c.end - c.start
      if (c.duration < 0)
        c.duration = 0
      return c
    })
    }

    this.resize()
    this.fetchMedia()
    window.addEventListener('resize', this.resize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    setDuration(index, duration) {
      this.previewMedia[index].duration = duration
      this.trigger++
    },
    makeTitle(item) {
      return `${item.name}\n${item.width}x${item.height}px`
    },
    deleteMedia(index) {
      this.previewMedia.splice(index, 1) 
    },
    selectMedia(item) {
      if (item.duration) {
        item.videoDuration = item.duration
      }
      else {
        item.duration = 10
      }
      // fix what is expected to be there
      item.id = item.mediaId
      item.position = 'middle,center'
      this.previewMedia.push(item)
    },
    fetchMedia: _.debounce(function() {
      var scope = this
      let url = 'v1/media?limit=all'
      url += this.restrict ? '&restrict' : ''
      this.$axios.get(url).then(
      (response) => {
        this.media = response.data.data.sort((a,b) => (a.mediaId < b.mediaId ? 1 : -1))
        this.loading = false
      }).catch(
        function (error) {
          if (!error.response) {
            scope.$notifications.add({
              title: 'Error',
              type: 'warning',
              body: error.message,
              timeout: true
            })
          } else if (error.response.status !== 404) {
            scope.$notifications.add({
              title: 'Error',
              type: 'warning',
              body: error.response.data.message,
              timeout: true
            })
          }
          scope.media = []
          scope.loading = false
        }
      )
    }, 1000),
    save () { // save button function
      // this is just for updating the name
      this.$axios.post('v1/campaigns/' + this.campaign.campaignId, this.campaign)

      // should switch all this to axios asap
      this.$axios.post('v1/campaigns/' + this.campaign.campaignId + '/media', this.previewMedia).then(
        () => {
          this.fetchMedia()
          this.$emit('saved')
          this.$notifications.add({
            title: 'Success',
            type: 'success',
            body: this.$t('notifications.campaignSave'),
            timeout: true
          })
        },
        () => {
          this.$notifications.add({
            title: 'Error',
            type: 'danger',
            body: this.$t('notifications.campaignSaveError'),
            timeout: true
          })
        })
    },
    resize: _.debounce(
      function () {
        // add check for horizontal width only
        let width = verge.viewportW()
        if      (width > 1900) { this.viewport = 'xl' }
        else if (width > 1200) { this.viewport = 'lg' }
        else if (width > 992)  { this.viewport = 'md' }
        else if (width > 768)  { this.viewport = 'sm' }
        else if (width > 480)  { this.viewport = 'xs' }
        else                   { this.viewport = 'xs' }
    }, 200),
  
    isMediaLibrary(library){
      if (library) {
        this.showLibrary=true
        this.showCampaignList=false
      }else{
        this.showLibrary=false
        this.showCampaignList=true
      }
    },

    isMobile(isMobile){
      if (isMobile) {
        this.showLibrary=false
        this.showCampaignList=true        
      }else{
        this.showLibrary=true
        this.showCampaignList=true

      }
    },
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
@import "node_modules/bootstrap/scss/_functions.scss";
@import "node_modules/bootstrap/scss/_variables.scss";
@import "node_modules/bootstrap/scss/mixins/_breakpoints.scss";

.name-trim {
  font-size: 100%;
  display: block;
  width: 100%;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-group-item:hover {
  border: 2px dashed red;
}

.ghost {
  opacity: 0.5;
  background: red;
}

.media-item {
  cursor: cell;
  border: 3px solid white;
}

.media-item:hover {
  box-sizing: border-box;
  border: 3px solid lightskyblue;
}

.spinner {
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.handle {
  float: left;
  padding-top: 2px;
  padding-bottom: 2px;
  cursor: grab;
}
</style>
