<template xmlns:v-bind="http://www.w3.org/1999/xhtml">
  <b-container id="verge">
  <!-- this is the only sane way I can think of doing this -->
  <b-row class="d-none d-md-block">
    <b-col class="m-2 d-flex justify-content-center">
      <div class="m-1">
        <!-- <b>Aspect Ratio: </b> -->
        <b-button-group class="m-1">
          <b-button class="font-weight-bold" size="sm" v-for="aspect in Object.keys(aspects)" :key="aspect" :variant="selectedAspect === aspect ? 'dark' : 'outline-dark'" @click="selectedAspect = aspect">{{ aspect }}</b-button>
        </b-button-group>
        <b-button-group class="m-1">
          <b-button class="font-weight-bold" size="sm" :variant="isRotated ? 'secondary' : 'outline-secondary'" @click="isRotated = !isRotated">Rotate <b-icon :icon="isRotated ? 'check-square' : 'square'" :variant="isRotated ? 'light' : 'dark'"></b-icon></b-button>
        </b-button-group>
      </div>
    </b-col>
  </b-row>
  <b-row>
    <b-col>
      <prop-player v-if="true" :noControls="false" class="m-0 p-0 mx-auto mb-4" :style="computedAspectStyle" :media="computedMedia"></prop-player>
    </b-col>
  </b-row>
  <b-row>
    <b-col cols="12" sm="6">
      <b-card :header="$t('common.tags')">
        <b-card-text>
          <b-form-group :description= "$t('campaigns.campaignTagDesc')">
            <multiselect
              :options="alltags"
              :value="selected"
              :multiple="true"
              :taggable="true"
              @tag="addTag"
              @select="chooseTag"
              @remove="removeTag"
              :placeholder="$t('message.addTag')"
            ></multiselect>
          </b-form-group>
          <b-button variant="primary" v-on:click="saveTags()">{{$t('buttons.save')}}</b-button>
        </b-card-text>
      </b-card>
    </b-col>
    <b-col cols="12" sm="6">
      <b-card :header="$t('campaigns.scheduledOn')">
        <b-card-text>
          <dl>
            <dd v-for="display in campaign.displays" :key="display.id">
              <b-link :to="{ name: 'display-view', params: { displayId: display.id }}">{{ display.name }}</b-link>
            </dd>
          </dl>
        </b-card-text>
      </b-card>
    </b-col>
  </b-row>
  </b-container>
</template>

<style>

.preview {
  width: 99%;
  text-align: center;
}

.thick-border {
  border-width: 2px;
}

</style>

<script>
import Multiselect from 'vue-multiselect'
import PropPlayer from '@/components/Player/PropPlayer.vue'
import teamContext from '@/mixins/teamContext'
import verge from 'verge'
import _ from 'lodash'

export default {
  name: 'EditCampaign',
  mixins: [teamContext],
  props: {
    campaign: {
      type: Object,
      required: true
    },
  },
  components: {
    Multiselect,
    PropPlayer,
  },
  data () {
    return {
      // tags
      tags: [{
        tagId: '',
        tag: ''
      }],
      alltags: [],
      oldtags: [],
      newtags: [],
      rect: {},
      selectedTeam: '',
      currentTeam: '',
      selected: [],
      displays: {},
      /* values:
        9:16
        10:16
        3:4

        16:9 -> 85 x 48
        16:10 -> 77 x 48
        4:3 -> 64 x 48
        1:1 -> 48 x 48
      */
      aspects: {
        '16:10':  {width: 77, height: 48},
        '16:9':   {width: 85, height: 48},
        '5:3':    {width: 80, height: 48},
        '4:3':    {width: 64, height: 48},
        '3:2':    {width: 72, height: 48},
        '2:1':    {width: 96, height: 48},
        '1:1':    {width: 48, height: 48},
      },
      isRotated: false,
      selectedAspect: '16:9', // just a default
      // selectedWidth: '48vh',
      // selectedHeight: '77vh',
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handlePreview)
  },
  mounted () {
    this.fetchCampaignTags()
    this.handlePreview()
    window.addEventListener('resize', this.handlePreview)
    this.detectAspect()
  },
  computed: {
    // i realise that this could probably just be done with v-if but... eh
    // [Vue warn]: Error in render: "TypeError: Cannot read property 'data' of undefined"
    computedMedia() {
      if (this.campaign.media && this.campaign.media.data) {
        return this.campaign.media.data
      }
      else {
        return []
      }
    },
    computedAspectStyle() {
      if (this.isRotated == true) {
        return {
          width: this.rect.width * Number('0.'+this.aspects[this.selectedAspect].height) +'px',
          height: (this.rect.width * 1.111) * Number('0.'+this.aspects[this.selectedAspect].width) +'px',
        }
      }
      else {
        return {
          width: this.rect.width * Number('0.'+this.aspects[this.selectedAspect].width) +'px',
          height: (this.rect.width * 1.111) * Number('0.'+this.aspects[this.selectedAspect].height) +'px',
        }
      }
    }
  },
  methods: {
    handlePreview: _.debounce(
      function () {
        this.rect = verge.rectangle(document.querySelector('#verge'))
    }, 200),
    detectAspect() {
      function gcd(a, b) {
        return (b == 0) ? a : gcd (b, a%b);
      }

      let max_w = this.campaign.media.data.reduce((a,c) => Math.max(a,c.width), 0)
      let max_h = this.campaign.media.data.reduce((a,c) => Math.max(a,c.height), 0)

      if (max_w !== 0 && max_h !== 0) {
        let r = gcd(max_w, max_h)
        let aspect = max_w/r + ':' + max_h/r

        if (this.aspects[aspect]) {
          this.selectedAspect = aspect
        }
      }
      // otherwise just use default value (16:9)
    },
    fetchCampaignTags () {
      var scope = this
      this.$axios.get('v1/campaigns/' + this.campaign.campaignId + '/tags').then(
      (response) => {
        this.tags = response.data.data
        this.meta = response.data.meta
        for (var t in this.tags) {
          this.alltags.push(this.tags[t].tag)
          this.selected.push(this.tags[t].tag)
        }
      }).catch(
        function (error) {
          if (!error.response) {
            scope.$notifications.add({
              title: 'Error',
              type: 'warning',
              body: error.message,
              timeout: true
            })
          } else if (error.response.status !== 404) {
            scope.$notifications.add({
              title: 'Error',
              type: 'warning',
              body: error.response.data.message,
              timeout: true
            })
          }
        }
      )
    },
    saveTags () {
      // get ids of removed tags - then remove
      for (var r = 0; r < this.oldtags.length; r++) {
        for (var t = 0; t < this.tags.length; t++) {
          if (this.tags[t].tag === this.oldtags[r]) {
            this.$axios.delete('v1/campaigns/' + this.campaign.campaignId + '/tags/' + this.tags[t].tagId).then(
            () => {
              this.$notifications.add({
                title: 'Success',
                type: 'success',
                body: this.tags[t].tag + ' ' + this.$t('notifications.tagRemove'),
                timeout: true
              })
            },
            (response) => {
              this.$notifications.add({
                title: 'Error',
                type: 'danger',
                body: JSON.stringify(response),
                timeout: true
              })
            })
            break
          }
        }
      }
      // create new tags
      for (var n in this.newtags) {
        // doing the adding
        if (this.newtags[n].length > 255) {
          this.$notifications.add({
            title: 'Error',
            type: 'danger',
            body: this.$t('notifications.tagLengthError'),
            timeout: true
          })
        } else {
          this.$axios.post('v1/campaigns/' + this.campaign.campaignId + '/tags', {tag: this.newtags[n].trim()}).then(
          () => {
            this.$notifications.add({
              title: 'Success',
              type: 'success',
              body: this.newtags[n].trim() + ' ' + this.$t('notifications.tagAdded'),
              timeout: true
            })
          },
          (response) => {
            this.$notifications.add({
              title: 'Error',
              type: 'danger',
              body: JSON.stringify(response),
              timeout: true
            })
          })
        }
      }
    },
    // add new tag to tags and selections
    addTag (newTag) {
      this.alltags.push(newTag)
      this.selected.push(newTag)
      this.newtags.push(newTag)
    },
    // select a tag
    chooseTag (newTag) {
      this.selected.push(newTag)
      var index = this.oldtags.indexOf(newTag)
      if (index > -1) {
        this.oldtags.splice(index, 1)
      }
    },
    // remove tag from selections
    removeTag (oldTag) {
      var index = this.selected.indexOf(oldTag)
      if (index > -1) {
        this.selected.splice(index, 1)
      }
      this.oldtags.push(oldTag)
    }
  }
}
</script>
