<template lang="html">
  <div>
    <h2>{{$t('schedule.selectCampaign')}}</h2>
    <div class="alert alert-danger" v-if="errors.length > 0">
      <ul>
        <li v-bind:key="index" v-for="(error, index) in errors">
          {{ error }}
        </li>
      </ul>
    </div>
    <multiselect
      :options="filteredCampaigns"
      :searchable="true"
      v-model="campaign"
      :placeholder="$t('schedule.selectCampaign')"
      label="name"
      @input="selectCampaign"
    >
  </multiselect>
  <b-button variant="primary" class="float-right mt-2" @click="next">{{$t('buttons.next')}}</b-button>
</div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },
  data () {
    return {
      campaigns: [],
      campaign: {},
      errors: []
    }
  },
  mounted () {
    this.fetchCampaigns()
  },
  computed: {
    filteredCampaigns () {
      return this.campaigns.filter((campaign) => {
        if (campaign.media.data.length > 0) {
          return true
        }
        return false
      })
    }
  },
  methods: {
    fetchCampaigns () {
      var scope = this
      this.$axios.get('v1/campaigns?limit=all&include=media').then(
        (response) => {
          this.campaigns = response.data.data
        }).catch(
        function (error) {
          if (!error.response) {
            scope.$notifications.add({
              title: 'Error',
              type: 'warning',
              body: error.message,
              timeout: true
            })
          } else if (error.response.status !== 404) {
            scope.$notifications.add({
              title: 'Error',
              type: 'warning',
              body: error.response.data.message,
              timeout: true
            })
          }
        }
      )
    },
    selectCampaign () {
      this.errors = []
      this.$emit('campaignSelected', this.campaign)
    },
    next () {
      if (Object.keys(this.campaign).length > 0) {
        this.$emit('next')
      } else {
        this.errors = [
          'Please select a campaign.'
        ]
      }
    }
  }
}
</script>

<style lang="css">
</style>
