<template>
    <b-tabs content-class="mt-3">
    <b-tab @click="() => this.$router.push({ name: 'log', params: { booking: this.booking } })" title="Overview" active>
        <router-view :booking="booking"></router-view>
    </b-tab>
    <b-tab title="Complete Log">
        <b-button variant="primary" :href="rootUrl + '/api/ext/log/' + booking.id">Download Playback Log (csv format)
        </b-button>
    </b-tab>
    <b-tab @click="() => this.$router.push({ name: 'report', params: { booking: this.booking } })" title="Report">
        <router-view :booking="booking"></router-view>
    </b-tab>
    </b-tabs>
</template>

<script>
import 'vue-awesome/icons/arrow-left'
import 'vue-awesome/icons/plus'

export default {
  name: 'TabContainer',
  props: {
    booking: {
      type: Object,
      required: true
    },
  },
  components: {
  },
  methods: {
    openReport() {
        
    }
  },
  data() {
    return {
      rootUrl: process.env.VUE_APP_API_ROOT
    }
  },
}

</script>