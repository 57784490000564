<template lang="html">
  <audio :src="audio.uri" ref="audio"></audio>
</template>

<script>
import showing from '../../mixins/show.js'

export default {
  props: {
    audio: {
      type: Object,
      required: true
    },
    time: {
      type: Number,
      required: true
    },
    playing: {
      type: Boolean,
      required: true
    }
  },
  mixins: [
    showing
  ],
  time: {
    handler (newTime, oldTime) {
      this.isShowing(this.audio)
      if (!this.playing) {
        if (this.showing) {
          this.$refs.audio.currentTime = this.time - this.audio.start
        }
      } else {
        if (this.showing && (newTime !== (oldTime + 1))) {
          this.$refs.audio.pause()
          this.$refs.audio.currentTime = this.time - this.audio.start
          this.$refs.audio.play()
        }
      }
    }
  },
  showing (isShowing, wasShowing) {
    if (!wasShowing && isShowing) {
      this.$refs.audio.play()
    } else if (wasShowing && !isShowing) {
      this.$refs.audio.pause()
      this.$refs.audio.currentTime = 0
    }
  },
  playing (isPlaying, wasPlaying) {
    if (wasPlaying && !isPlaying) {
      if (this.showing) {
        this.$refs.audio.pause()
      }
    } else if (!wasPlaying && isPlaying) {
      if (this.showing) {
        this.$refs.audio.currentTime = this.time - this.audio.start
        this.$refs.audio.play()
      }
    }
  }
}
</script>

<style lang="css">
</style>
