<template>
  <b-row>
    <b-modal id="delete-modal" :title="$t('actions.delete') + ' ' + campaign.name">
      {{$t('message.campaignDelete')}}
      <div slot="modal-footer">
        <button type="button" class="btn delete-button btn-primary left" @click="deleteCampaign(); $bvModal.hide('delete-modal')">
        {{$t('answers.yes')}}</button>
        <button type="button" class="btn delete-button btn-primary right" @click="$bvModal.hide('delete-modal')">
        {{$t('answers.no')}}</button>
      </div>
    </b-modal>
    <b-modal id="edit-modal" no-close-on-backdrop size="xl" hide-footer>
      <template #modal-title>
        <b-input class="wvh-50" placeholder="Campaign Name" v-model="campaign.name"></b-input>
      </template>
      <div class="alert alert-danger" v-if="errors.length > 0">
        <ul>
          <li v-bind:key="index" v-for="(error, index) in errors">
            {{ error }}
          </li>
        </ul>
      </div>
      <campaign-editor class="" v-if="campaign !== {}" :activeTeam="activeTeam" :campaign="campaign" @saved="$emit('close')"></campaign-editor>
    </b-modal>
    <b-col cols="12" class="campaign-nav w-100">
      <div class="d-flex justify-content-center align-items-center">
        <h1 class="campaign-name d-none d-md-block">{{campaign.name}}</h1>
        <b-button-toolbar class="">
          <b-button class="m-1 border-primary text-primary" variant="light" v-b-modal.edit-modal>
            <b-icon icon="card-list"></b-icon> Edit Campaign <!-- TODO -->
          </b-button>
          <b-button class="m-1 border-danger text-danger" variant="light" v-b-modal.delete-modal>
            <b-icon icon="x-octagon"></b-icon> {{$t('buttons.delete')}}
          </b-button>
        </b-button-toolbar>
      </div>
    </b-col>
    <b-col cols="12" class="h-100 campaign-main">
      <div class="mt-2 mb-2 alert alert-danger" v-if="errors.length > 0">
        <ul>
          <li v-bind:key="index" v-for="(error, index) in errors">
            {{ error }}
          </li>
        </ul>
      </div>
      <transition name="fade">
        <edit-campaign :activeTeam="activeTeam" :campaign="campaign" @update="emit('update')"></edit-campaign>
      </transition>
    </b-col>
  </b-row>
</template>

<style scoped lang="scss">
.wvh-50 {
  width: 50vw;
}

  .campaign-nav {
    z-index: 1001;
  }

  .campaign-main {
    z-index: 1000;
  }

  @import "node_modules/bootstrap/scss/_functions.scss";
  @import "node_modules/bootstrap/scss/_variables.scss";
  @import "node_modules/bootstrap/scss/mixins/_breakpoints.scss";

    .campaign-name {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
</style>


<script>
import EditCampaign from './EditCampaign.vue'
import CampaignEditor from '@/components/CampaignEditor/CampaignEditor.vue'
import 'vue-awesome/icons/ellipsis-v'
import teamContext from '@/mixins/teamContext'

export default {
  name: 'Campaign',
  mixins: [teamContext],
  components: {
    EditCampaign,
    CampaignEditor
  },
  props: {
    campaign: {
      type: Object,
      required: true
    },
  },
  mounted() {
  },
  data () {
    return {
      errors: [],
      dropdownOpen: false,
      created: false,
    }
  },
  beforeDestroy () {
    this.$emit('refresh')
  },
  methods: {
    deleteCampaign () {
      this.$axios.delete('v1/campaigns/' + this.campaign.campaignId).then(
      () => {
        this.$notifications.add({
          title: 'Success',
          type: 'success',
          body: this.$t('notifications.campaignDelete'),
          timeout: true
        })
        this.$emit('delete')
      },
      () => {
        this.$notifications.add({
          title: 'Error',
          type: 'danger',
          body: this.$t('notifications.campaignDeleteError'),
          timeout: true
        })
      })
    },
  }
}
</script>
